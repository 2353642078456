import React from "react";
import { AGE } from "../utils/constants";
import LoaderAge from "../assets/loading_age.gif";
import LoaderFeerate from "../assets/loading_feerate.gif";

const Loader = ({ type, ...rest }) => {
  return type === AGE ? (
    <img
      src={LoaderAge}
      alt="Loading age"
      style={{ width: 125, margin: "24px 0" }}
      {...rest}
    />
  ) : (
    <img
      src={LoaderFeerate}
      alt="Loading feerate"
      style={{ width: 125, margin: "24px 0" }}
      {...rest}
    />
  );
};

export default Loader;
