import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LS_SEARCH_QUERY } from "../utils/constants";

const SearchField = ({ searchQuery, setSearchQuery, search, handleClick }) => {
  const navigate = useNavigate();
  const [val, setVal] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (window.location.pathname !== "/all") {
      await localStorage.setItem(LS_SEARCH_QUERY, val || searchQuery);
      return navigate("/all");
    }

    await search(val || searchQuery);
  };

  return (
    <form className="search-inp" onSubmit={handleSubmit}>
      <i className="fa-solid fa-search opacity-50"></i>

      <input
        id="search-bar"
        type="text"
        style={{ flex: 1 }}
        placeholder={searchQuery || "Search by block number"}
        value={val}
        onFocus={() => setVal("")}
        onBlur={() => setVal(searchQuery)}
        onChange={(e) => {
          setVal(e.target.value);
          setSearchQuery(e.target.value);
        }}
      />

      {val || searchQuery ? (
        <i
          className="fa-solid fa-close opacity-50"
          style={{ cursor: "pointer" }}
          onClick={async () => {
            setVal("");
            setSearchQuery("");

            search("");
          }}
        ></i>
      ) : null}

      <button
        type="submit"
        className="ms-3 default-btn"
        style={{ color: "white" }}
      >
        <i className="fa-solid fa-arrow-right"></i>
      </button>
    </form>
  );
};

export default SearchField;
