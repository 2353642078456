import React from "react";

const Logo = ({ handleClick = () => {}, style }) => {
  return (
    <div className="logo-block" onClick={handleClick} style={style}>
      <img src="/images/logo.png" alt="Bitmap View - Logo" height={40} />
    </div>
  );
};

export default Logo;
