import React from "react";
import Logo from "./Logo";
import SearchField from "./SearchField";
import UnisatWallet from "./UnisatWallet";
import { Link, Navigate } from "react-router-dom";

const MainHeader = ({
  hasSearch,
  search,
  customSearchClick = () => {},
  isSecondary,
  bitmap,
  searchQuery,
  hasWallet,
  wallets,
  walletLoading,
  walletItems,
  itemsInWallet,
  setSearchQuery,
  setHasWallet,
  setWallets,
  setWalletLoading,
  setWalletItems,
  setWalletFilter,
  resetState,
}) => {
  return (
    <div style={{ marginBottom: 34 }}>
      <Link to="/" className="highlight ms-3">
        <Logo
          style={{ cursor: "pointer" }}
          handleClick={() => <Navigate to="/all" />}
        />
      </Link>

      {hasSearch ? (
        <SearchField
          search={search}
          handleClick={customSearchClick}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      ) : null}

      <UnisatWallet
        bitmap={bitmap}
        hasWallet={hasWallet}
        wallets={wallets}
        walletLoading={walletLoading}
        walletItems={walletItems}
        itemsInWallet={itemsInWallet}
        setHasWallet={setHasWallet}
        setWallets={setWallets}
        setWalletLoading={setWalletLoading}
        setWalletItems={setWalletItems}
        setWalletFilter={setWalletFilter}
        resetState={resetState}
      />

      {/* <SocialIcons /> */}
    </div>
  );
};

export default MainHeader;
