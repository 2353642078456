import React, { useEffect, useState } from "react";
import Thumbnail from "./Thumbnail";
import ImageModal from "./ImageModal";
import { getActiveInscriptions } from "../services/wallet";
import {
  calculateItemsInRow,
  fetchImageData,
  getCategoryBitmaps,
  getWalletImages,
  searchImages,
} from "../utils/functions";
import {
  AGE,
  ROW_INCR,
  LS_ITEMS_IN_ROW,
  LS_UNISAT_WALLET,
  LS_IS_WALLET_FILTER,
  LS_WALLET_ITEMS,
  LS_SEARCH_QUERY,
} from "../utils/constants";
import MainHeader from "./MainHeader";
import Categories from "./Categories";
import LoadingInfo from "./LoadingInfo";
import CategoriesFilter from "./Filters/CategoriesFilter";
import { useParams } from "react-router-dom";

const Gallery = () => {
  const ls_itemsInRow = localStorage.getItem(LS_ITEMS_IN_ROW);
  const ls_unisatWallet = localStorage.getItem(LS_UNISAT_WALLET);
  const ls_isWalletFilter = localStorage.getItem(LS_IS_WALLET_FILTER);
  const ls_walletItems = localStorage.getItem(LS_WALLET_ITEMS);

  // const url = new URL(window.location);
  const searchTerm = localStorage.getItem(LS_SEARCH_QUERY);
  const itemsInWallet = ls_walletItems ? JSON.parse(ls_walletItems) : [];
  const walletFilterValue = ls_isWalletFilter
    ? JSON.parse(ls_isWalletFilter)
    : false;

  const [searchQuery, setSearchQuery] = useState("");
  const [imageData, setImageData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [displayedImages, setDisplayedImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [map, setMap] = useState(false);
  const [noMore, setNoMore] = useState(false);
  const [pointer, setPointer] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [bitmap, setBitmap] = useState(AGE);
  const [allImages, setAllImages] = useState([]);
  const [itemsInRow, setItemsInRow] = useState(ls_itemsInRow || 7);
  const [imagesPerScroll, setImagesPerScroll] = useState(7 * ROW_INCR);
  const [hasWallet, setHasWallet] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [walletFilter, setWalletFilter] = useState(walletFilterValue);
  const [walletItems, setWalletItems] = useState(itemsInWallet);
  const [walletLoading, setWalletLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [punk, setPunk] = useState(false);
  const [isSearch, setIsSearch] = useState(true);

  const resetState = (params = {}) => {
    setIsSearch(false);
    setAllImages([]);
    setImageData([]);
    setDisplayedImages([]);
    setPointer(0);
    setPageNum(0);
    setLoading(true);
    setNoMore(false);
    setMap(false);

    const { isWallet, notCategory } = params;

    if (!isWallet) setWalletFilter(walletFilterValue);
    if (!notCategory) {
      setCategory("");
      setPunk(false);
    }
  };

  useEffect(() => {
    const itemsRow = localStorage.getItem(LS_ITEMS_IN_ROW);
    if (itemsRow && itemsRow != itemsInRow) setItemsInRow(itemsRow);

    const accounts = JSON.parse(localStorage.getItem(LS_UNISAT_WALLET));
    if (accounts && accounts.length) setWallets(accounts);
  }, [searchTerm]);

  const search = async (val) => {
    setLoading(true);

    if (!val.trim()) {
      setSearchQuery("");
      setIsSearch(false);
      resetState();
    } else if (val || searchQuery || searchTerm) {
      setImageData([]);

      const newData = await searchImages(val || searchQuery, bitmap);

      setImageData(newData || []);
      setLoading(false);
    } else if (
      (!val || !searchQuery || !searchTerm) &&
      !walletFilter &&
      !category
    ) {
      resetState();

      return fetchImageData(
        pageNum,
        bitmap,
        map,
        setPageNum,
        setImageData,
        setLoading,
        setNoMore
      );
    }
  };

  useEffect(() => {
    setIsSearch(!!searchTerm);

    if (searchTerm) {
      setSearchQuery(searchTerm);
      search(searchTerm);

      localStorage.removeItem(LS_SEARCH_QUERY);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (!searchTerm && searchQuery) search(searchQuery);
  }, [searchQuery, bitmap]);

  useEffect(() => {
    setDisplayedImages(imageData.slice(0, imagesPerScroll));
  }, [imageData, imagesPerScroll, searchQuery, walletFilter]);

  useEffect(() => {
    if (
      !walletFilter &&
      !category &&
      !searchTerm &&
      !searchQuery &&
      !isSearch
    ) {
      (async () =>
        await fetchImageData(
          pageNum,
          bitmap,
          map,
          setPageNum,
          setImageData,
          setLoading,
          setNoMore
        ))();
    }

    calculateItemsInRow();
  }, [
    isSearch,
    searchQuery,
    searchTerm,
    map,
    punk,
    bitmap,
    imagesPerScroll,
    itemsInRow,
    walletFilter,
    walletItems,
    category,
  ]);

  return (
    <div className="gallery-container">
      <MainHeader
        hasSearch
        search={search}
        bitmap={bitmap}
        loading={loading}
        walletFilter={walletFilter}
        searchQuery={searchQuery}
        hasWallet={hasWallet}
        wallets={wallets}
        walletLoading={walletLoading}
        walletItems={walletItems}
        itemsInWallet={itemsInWallet}
        setSearchQuery={setSearchQuery}
        setHasWallet={setHasWallet}
        setWallets={setWallets}
        setWalletLoading={setWalletLoading}
        setWalletItems={setWalletItems}
        setWalletFilter={setWalletFilter}
        resetState={resetState}
      />

      <CategoriesFilter
        loading={loading}
        bitmap={bitmap}
        punk={punk}
        category={category}
      />

      <Categories
        loading={loading}
        category={category || "ALL"}
        bitmap={bitmap}
        map={map}
        punk={punk}
        setPunk={setPunk}
        setBitmap={setBitmap}
        setMap={setMap}
        setCategory={setCategory}
        resetState={resetState}
      />

      <div className="my-4">
        <Thumbnail
          isAll
          type={bitmap}
          map={map}
          imageData={imageData}
          displayedImages={displayedImages}
          onClick={(image) => setSelectedImage(image)}
        />
      </div>

      {selectedImage && (
        <ImageModal
          type={bitmap}
          image={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}

      <LoadingInfo
        hasFilters
        searchQuery={searchQuery}
        loading={loading}
        imageData={imageData}
        noMore={noMore}
        walletFilter={walletFilter}
        bitmap={bitmap}
        itemsInRow={itemsInRow}
        setLoading={setLoading}
        setImagesPerScroll={setImagesPerScroll}
        resetState={resetState}
      />
    </div>
  );
};

export default Gallery;
