import { toast } from "react-toastify";
import http from "./http";
import {
  LS_WALLET_ITEMS,
  MAX_BM_LENGTH,
  WALLET_ENDPOINT,
} from "../utils/constants";

export const getActiveInscriptions = async () => {
  if (!window.unisat) return undefined;

  try {
    const list = [];

    let i = 0;
    let total = 300;

    while (i <= total) {
      const res = await window.unisat.getInscriptions(i, 100);
      if (!res.list?.length) break;

      list.push(...res.list);

      const diff = res.total - i;
      if (!diff) break;

      i += diff < 100 ? diff : 100;
      total = res.total;
    }

    if (!list.length) return [];

    const inscriptions = list.filter(
      (bitmap) => bitmap.contentLength <= MAX_BM_LENGTH
    );

    if (inscriptions.length)
      localStorage.setItem(LS_WALLET_ITEMS, JSON.stringify(inscriptions));

    return inscriptions;
  } catch (err) {
    toast.error("An error occurred.");
  }
};

export const requestAccounts = async () => {
  if (!window.unisat) return undefined;

  try {
    return await window.unisat.requestAccounts();
  } catch (err) {
    toast.error("Failed to connect to the wallet.");
  }
};

export const getWallet = async (address) => {
  try {
    const { data, status } = await http.get(`${WALLET_ENDPOINT}/${address}`);

    if (status !== 200) {
      toast.error("Unable to get wallet.");
      return 0;
    }

    return data;
  } catch (err) {
    return http.isCancel(err) ? "CANCEL" : 0;
  }
};

export const storeOrUpdateWallet = async (address) => {
  try {
    const inscriptions = await getActiveInscriptions();
    const inscriptionIds = inscriptions.map((ins) => ins.inscriptionId);

    const wallet = await getWallet(address);
    const data =
      wallet !== "CANCEL"
        ? wallet && wallet.address
          ? await updateWallet(address, inscriptionIds)
          : await storeWallet(address, inscriptionIds)
        : 0;

    return data;
  } catch (err) {
    console.log(err);
    toast.error("Error occurred on wallet operations.");
    return 0;
  }
};

const storeWallet = async (address, inscriptionIds) => {
  try {
    const { data, status } = await http.post(`${WALLET_ENDPOINT}/create`, {
      address,
      inscriptionIds,
    });

    if (status !== 200) {
      toast.error("An error occurred while storing the wallet info.");
      return 0;
    }

    return data;
  } catch (err) {
    return 0;
  }
};

const updateWallet = async (address, inscriptionIds) => {
  try {
    const { data, status } = await http.put(
      `${WALLET_ENDPOINT}/${address}/update`,
      {
        address,
        inscriptionIds,
      }
    );

    if (status !== 200) {
      toast.error("An error occurred while updating the wallet info.");
      return 0;
    }

    return data;
  } catch (err) {
    return 0;
  }
};

export const likeBitmap = async (address, bitmapId) => {
  try {
    const { status, data } = await http.put(`${WALLET_ENDPOINT}/like`, {
      address,
      bitmapId,
    });

    if (status !== 200) {
      toast.error("Unable to get wallet bitmaps.");
      return 0;
    }

    return data;
  } catch (err) {
    if (err.response.status === 400) toast.error(err.response.data);
    return 0;
  }
};

export const dislikeBitmap = async (address, bitmapId) => {
  try {
    const { status, data } = await http.put(`${WALLET_ENDPOINT}/dislike`, {
      address,
      bitmapId,
    });

    if (status !== 200) {
      toast.error("Unable to get wallet bitmaps.");
      return 0;
    }

    return data;
  } catch (err) {
    if (err.response.status === 400) toast.error(err.response.data);
    return 0;
  }
};
