import jwtDecode from "jwt-decode";
import http from "./http";
import { AUTH_ENDPOINT, AUTH_TOKEN } from "../utils/constants";

export const register = (user) => {
  return http.post(AUTH_ENDPOINT, {
    name: user.name,
    email: user.username,
    password: user.password,
  });
};

export const login = async (email, password) => {
  const { data: jwt } = await http.post(`${AUTH_ENDPOINT}/login`, {
    email,
    password,
  });
  localStorage.setItem(AUTH_TOKEN, jwt);
};

export const loginWithJwt = (jwt) => {
  localStorage.setItem(AUTH_TOKEN, jwt);
};

export const logout = async () => {
  localStorage.removeItem(AUTH_TOKEN);
};

export const getCurrentUser = () => {
  try {
    const jwt = localStorage.getItem(AUTH_TOKEN);
    return jwtDecode(jwt);
  } catch (err) {
    return null;
  }
};

export const getJwt = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

http.setJwt(getJwt());
