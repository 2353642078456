import React from "react";

const ThumbsUp = ({ fill, style, className }) => {
  return (
    <svg
      className={className}
      style={{ ...style }}
      fill={fill || "#fab005"}
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m11 2-.775391 3.4179688-3.224609 3.5820312v12h11.25l3.75-8.4375v-3.5625h-9l1-4-1-3zm-9 7v12h3v-12z" />
    </svg>
  );
};

export default ThumbsUp;
