import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { capitalize } from "lodash";
import { checkCategory, getCategoryBitmaps } from "../utils/functions";
import {
  AGE,
  CATE_2TX,
  CATE_ALL_PUNKS,
  CATE_HEADS,
  CATE_PERFECT_PUNK,
  CATE_PUNK,
  CATE_SERIES,
  CATE_VBYTES,
  FEE_RATE,
  LS_IS_WALLET_FILTER,
  LS_ITEMS_IN_ROW,
  LS_UNISAT_WALLET,
  LS_WALLET_ITEMS,
  ROW_INCR,
} from "../utils/constants";
import Thumbnail from "../components/Thumbnail";
import LoadingInfo from "../components/LoadingInfo";
import ImageModal from "../components/ImageModal";
import { Tooltip } from "react-tooltip";
import MainHeader from "../components/MainHeader";
import SideFilters from "../components/SideFilters";
import CategoriesFilter from "../components/Filters/CategoriesFilter";
import Categories from "../components/Categories";
import ColorsFilter from "../components/Filters/ColorsFilter";

const Category = () => {
  const { type } = useParams();

  const ls_itemsInRow = localStorage.getItem(LS_ITEMS_IN_ROW);
  const ls_unisatWallet = localStorage.getItem(LS_UNISAT_WALLET);
  const ls_isWalletFilter = localStorage.getItem(LS_IS_WALLET_FILTER);
  const ls_walletItems = localStorage.getItem(LS_WALLET_ITEMS);

  const itemsInWallet = ls_walletItems ? JSON.parse(ls_walletItems) : [];
  const walletFilterValue = ls_isWalletFilter
    ? JSON.parse(ls_isWalletFilter)
    : false;

  const [searchQuery, setSearchQuery] = useState("");
  const [imageData, setImageData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [displayedImages, setDisplayedImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [map, setMap] = useState(false);
  const [noMore, setNoMore] = useState(false);
  const [pointer, setPointer] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [bitmap, setBitmap] = useState(AGE);
  const [allImages, setAllImages] = useState([]);
  const [itemsInRow, setItemsInRow] = useState(ls_itemsInRow || 7);
  const [imagesPerScroll, setImagesPerScroll] = useState(itemsInRow * ROW_INCR);
  const [hasWallet, setHasWallet] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [walletFilter, setWalletFilter] = useState(walletFilterValue);
  const [walletItems, setWalletItems] = useState(itemsInWallet);
  const [walletLoading, setWalletLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [punk, setPunk] = useState(false);
  const [vbytes, setVbytes] = useState(false);
  const [filters, setFilters] = useState({});

  const resetState = () => {
    setAllImages([]);
    setImageData([]);
    setDisplayedImages([]);
    setPointer(0);
    setPageNum(0);
    setLoading(true);
    setNoMore(false);
    setMap(false);
  };

  const removeEmptyKey = (key, temp) => {
    delete temp[key];
    setFilters(temp);
  };

  const handleFilter = (type, data) => {
    const d = { ...data };
    const t = type.split("_").join("");

    if (t === "date") {
      d.start = d.start ? d.start.toISOString() : "";
      d.end = d.end ? d.end.toISOString() : "";
    }

    const startKey = `${t}Start`;
    const endKey = `${t}End`;

    const temp = { ...filters };

    if (d.start.length) temp[startKey] = d.start;
    if (d.end.length) temp[endKey] = d.end;

    if (!d.start.length) removeEmptyKey(startKey, temp);
    if (!d.end.length) removeEmptyKey(endKey, temp);

    setFilters(temp);
    resetState();
  };

  const updateCategory = (cate, isPunk) => {
    if (category !== cate) {
      if (!isPunk) setPunk(false);

      if (!cate) setCategory(CATE_PUNK);
      else setCategory(category === cate ? CATE_PUNK : cate);

      resetState();
    }
  };

  const changePunkView = (isVbytes) => {
    if (vbytes !== isVbytes) {
      setVbytes(isVbytes);
      resetState();
    }
  };

  useEffect(() => {
    setVbytes(bitmap === FEE_RATE);
  }, [bitmap]);

  useEffect(() => {
    setCategory(type);
    resetState();
  }, [type]);

  useEffect(() => {
    const itemsRow = localStorage.getItem(LS_ITEMS_IN_ROW);
    if (itemsRow && itemsRow != itemsInRow) setItemsInRow(itemsRow);

    const accounts = JSON.parse(localStorage.getItem(LS_UNISAT_WALLET));
    if (accounts && accounts.length) setWallets(accounts);
  }, []);

  useEffect(() => {
    setDisplayedImages(imageData.slice(0, imagesPerScroll));
  }, [imageData, imagesPerScroll]);

  useEffect(() => {
    (async () =>
      await getCategoryBitmaps(
        bitmap,
        category,
        allImages,
        imageData,
        map,
        pointer,
        setAllImages,
        setImageData,
        setPointer,
        setLoading,
        setNoMore,
        CATE_ALL_PUNKS.includes(type) ? vbytes : false,
        filters
      ))();
  }, [
    map,
    punk,
    bitmap,
    imagesPerScroll,
    itemsInRow,
    type,
    category,
    vbytes,
    filters,
  ]);

  if (!checkCategory(type)) return <Navigate to="/" />;

  return (
    <div className="gallery-container">
      <MainHeader
        hasSearch
        customSearchClick={() => <Navigate to="/all" />}
        bitmap={bitmap}
        loading={loading}
        walletFilter={walletFilter}
        searchQuery={searchQuery}
        hasWallet={hasWallet}
        wallets={wallets}
        walletLoading={walletLoading}
        walletItems={walletItems}
        itemsInWallet={itemsInWallet}
        setSearchQuery={setSearchQuery}
        setHasWallet={setHasWallet}
        setWallets={setWallets}
        setWalletLoading={setWalletLoading}
        setWalletItems={setWalletItems}
        setWalletFilter={setWalletFilter}
        resetState={resetState}
      />

      <CategoriesFilter
        loading={loading}
        bitmap={vbytes ? FEE_RATE : AGE}
        punk={punk}
        category={type}
      />

      <Categories
        isCategory
        loading={loading}
        category={type.toUpperCase()}
        bitmap={bitmap}
        map={map}
        punk={punk}
        setPunk={setPunk}
        setBitmap={setBitmap}
        setMap={setMap}
        setCategory={setCategory}
        resetState={resetState}
      />

      {type === CATE_SERIES && (
        <ColorsFilter
          isCategory
          bitmap={bitmap}
          loading={loading}
          setBitmap={setBitmap}
          resetState={resetState}
        />
      )}

      {type === CATE_PUNK && (
        <>
          <div
            className="d-flex align-items-center category-item"
            style={{ margin: "1rem 0" }}
          >
            <p
              className="mb-0"
              style={{
                fontWeight: "bold",
                width: 108,
              }}
            >
              Worlds
            </p>

            <div className="land-btn-container w-100">
              <button
                disabled={loading}
                onClick={() => changePunkView(false)}
                className={
                  vbytes
                    ? `land-btn-blue ${!vbytes && "land-btn-blue-active"}`
                    : `land-btn ${!vbytes && "land-btn-active"}`
                }
              >
                <img
                  src={`/images/punk-${!vbytes ? "dark" : "blue"}.png`}
                  alt="Punk"
                />{" "}
                <span>+ Punk</span>
              </button>

              <button
                data-tooltip-id="neg-punk-tooltip"
                disabled={loading}
                onClick={() => changePunkView(true)}
                className={`land-btn ${
                  vbytes && "land-btn-blue land-btn-blue-active"
                }`}
              >
                <img
                  src={`/images/punk-${vbytes ? "dark" : "orange"}.png`}
                  alt="Punk"
                />{" "}
                <span>- Punk</span>
              </button>
            </div>
          </div>
          <div
            className="d-flex align-items-center category-item"
            style={{ marginTop: "1rem", marginBottom: "3rem" }}
          >
            <p
              className="mb-0"
              style={{
                fontWeight: "bold",
                width: 108,
              }}
            >
              Traits
            </p>

            <div className="w-100 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center w-100 filter-btns">
                <div className="land-btn-container me-3">
                  <button
                    disabled={loading}
                    onClick={() => updateCategory(CATE_PUNK, true)}
                    className={
                      vbytes
                        ? `land-btn-blue punkmap-btn ${
                            category === CATE_PUNK && "land-btn-blue-active"
                          }`
                        : `land-btn punkmap-btn ${
                            category === CATE_PUNK && "land-btn-active"
                          }`
                    }
                  >
                    All Punks
                  </button>
                </div>

                <div className="land-btn-container me-3">
                  <button
                    disabled={loading}
                    onClick={() => updateCategory(CATE_PERFECT_PUNK, true)}
                    className={
                      vbytes
                        ? `land-btn-blue punkmap-btn ${
                            category === CATE_PERFECT_PUNK &&
                            "land-btn-blue-active"
                          }`
                        : `land-btn punkmap-btn ${
                            category === CATE_PERFECT_PUNK && "land-btn-active"
                          }`
                    }
                  >
                    Perfect punk
                  </button>
                </div>

                <div className="land-btn-container me-3">
                  <button
                    disabled={loading}
                    onClick={() => updateCategory(CATE_HEADS, true)}
                    className={
                      vbytes
                        ? `land-btn-blue punkmap-btn ${
                            category === CATE_HEADS && "land-btn-blue-active"
                          }`
                        : `land-btn punkmap-btn ${
                            category === CATE_HEADS && "land-btn-active"
                          }`
                    }
                  >
                    Heads
                  </button>
                </div>

                <div className="land-btn-container me-3">
                  <button
                    disabled={loading}
                    onClick={() => updateCategory(CATE_2TX, true)}
                    className={
                      vbytes
                        ? `land-btn-blue punkmap-btn ${
                            category === CATE_2TX && "land-btn-blue-active"
                          }`
                        : `land-btn punkmap-btn ${
                            category === CATE_2TX && "land-btn-active"
                          }`
                    }
                  >
                    2Tx
                  </button>
                </div>

                <Tooltip
                  id="neg-punk-tooltip"
                  className="tooltip"
                  place="bottom"
                  content="The utility of the Negative view is predominantly in the realm of art. This perspective allows for an alternative aesthetic interpretation of the blockchain data, broadening the scope of the artistic narratives that can be created and explored. At this stage, the Negative view does not extend beyond the sphere of artistic interpretation and does not contribute to the functionality aspects within the Bitmap theory. For more information visit the Bitmap Punk Gitbook."
                />

                <Tooltip
                  id="submit-punk-tooltip"
                  className="tooltip"
                  place="bottom"
                  content="During the trial period of the voting system, this collection is continuously updated and revised based on community input. Contents and information are subject to change without prior notice."
                />
              </div>

              {/* <a
                data-tooltip-id="submit-punk-tooltip"
                href="https://docs.google.com/forms/d/19q5EmlN6WztyVS8PWPHAiEhd9yp-kqbgtIi9clR9i9I"
                target="_blank"
                rel="noreferrer"
                className={`land-btn punkmap-btn text-nowrap submit-punk-btn ${
                  vbytes && "submit-punk-btn-blue"
                }`}
              >
                Submit your punk
              </a> */}
            </div>
          </div>
        </>
      )}

      <div className="d-flex category-body" style={{ gap: 24 }}>
        <SideFilters
          handleFilter={handleFilter}
          isNegative={vbytes}
          category={category}
        />

        <Thumbnail
          isWithFilter
          category={category}
          isPunkNegative={vbytes}
          type={bitmap}
          map={map}
          imageData={imageData}
          displayedImages={displayedImages}
          onClick={(image) => setSelectedImage(image)}
        />
      </div>

      {selectedImage && (
        <ImageModal
          image={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}

      <LoadingInfo
        loading={loading}
        imageData={imageData}
        noMore={noMore}
        bitmap={vbytes ? FEE_RATE : bitmap}
        itemsInRow={itemsInRow}
        setLoading={setLoading}
        setImagesPerScroll={setImagesPerScroll}
        resetState={resetState}
      />
    </div>
  );
};

export default Category;
