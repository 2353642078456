import React, { useEffect, useState } from "react";
import {
  getActiveInscriptions,
  requestAccounts,
  storeOrUpdateWallet,
} from "../services/wallet";
import {
  FEE_RATE,
  LS_IS_WALLET_FILTER,
  LS_LIKES_DISLIKES,
  LS_UNISAT_WALLET,
  LS_UNISAT_WALLET_DATA,
  LS_WALLET_ITEMS,
} from "../utils/constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const UnisatWallet = ({
  bitmap,
  loading,
  walletFilter,
  hasWallet,
  wallets,
  walletLoading,
  walletItems,
  itemsInWallet,
  setHasWallet,
  setWallets,
  setWalletLoading,
  setWalletItems,
  setWalletFilter,
  resetState,
}) => {
  const navigate = useNavigate();
  const [itemsCount, setItemsCount] = useState(0);

  const connectUnisat = async () => {
    setWalletLoading(true);
    const accounts = await requestAccounts();

    if (accounts === undefined) return setHasWallet(false);
    if (accounts && accounts.length) {
      const { _id, address, bitmaps } = await storeOrUpdateWallet(accounts[0]);

      await localStorage.setItem(LS_UNISAT_WALLET, JSON.stringify(accounts));
      await localStorage.setItem(
        LS_UNISAT_WALLET_DATA,
        JSON.stringify({ _id, address, bitmaps })
      );

      setWallets(accounts);
      toast.success("Successfully conntected.");

      window.location.reload();
    }

    // setWalletLoading(false);
  };

  const disconnectUnisat = async () => {
    setWallets([]);
    toast.success("Successfully disconntected.");

    await localStorage.removeItem(LS_UNISAT_WALLET);
    await localStorage.removeItem(LS_UNISAT_WALLET_DATA);
    await localStorage.removeItem(LS_IS_WALLET_FILTER);
    await localStorage.removeItem(LS_WALLET_ITEMS);
    await localStorage.removeItem(LS_LIKES_DISLIKES);

    window.location.reload();
  };

  const getWalletItems = async (isUnisat) => {
    if (isUnisat) {
      const walletData = localStorage.getItem(LS_UNISAT_WALLET_DATA);

      if (walletData) {
        const data = JSON.parse(walletData);
        const length = _.uniqBy(data.bitmaps, "inscriptionId").length;

        setWalletItems(data.bitmaps);
        setItemsCount(length);
      } else {
        const walletBitmaps = await getActiveInscriptions();

        if (!walletItems.length) {
          localStorage.removeItem(LS_WALLET_ITEMS);
          localStorage.removeItem(LS_IS_WALLET_FILTER);

          resetState();
          setWalletItems([]);
          setWalletFilter(false);
        }

        setWalletItems(walletBitmaps || itemsInWallet);
        setItemsCount(walletItems.length);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await new Promise((r) => setTimeout(r, 1000));

      const isUnisat = typeof window.unisat !== "undefined";
      setHasWallet(isUnisat);

      if (wallets.length) getWalletItems(isUnisat);
    })();

    const length = _.uniqBy(walletItems, "inscriptionId").length;
    setItemsCount(length);
  }, []);

  return (
    <div className="wallet-btn-container">
      {hasWallet && !wallets.length ? (
        <button
          className="authBtn ms-auto"
          disabled={walletLoading}
          onClick={connectUnisat}
        >
          Connect Unisat Wallet
        </button>
      ) : (
        <></>
      )}

      {hasWallet && wallets.length ? (
        <div className="ms-auto pb-2">
          <button
            className="authBtn"
            disabled={walletLoading}
            onClick={disconnectUnisat}
          >
            {walletLoading ? "Connecting..." : "Disconnect Wallet"}
          </button>
        </div>
      ) : (
        <></>
      )}

      {!walletLoading && hasWallet && wallets.length ? (
        <div className="land-btn-container w-100">
          <button
            onClick={() => navigate("/all")}
            className={
              bitmap === FEE_RATE
                ? `land-btn-blue ${
                    window.location.pathname === "/all" &&
                    "land-btn-blue-active"
                  }`
                : `land-btn ${
                    window.location.pathname === "/all" && "land-btn-active"
                  }`
            }
          >
            All items
          </button>

          <button
            onClick={() => navigate("/wallet")}
            className={
              bitmap === FEE_RATE
                ? `land-btn-blue ${
                    window.location.pathname === "/wallet" &&
                    "land-btn-blue-active"
                  }`
                : `land-btn ${
                    window.location.pathname === "/wallet" && "land-btn-active"
                  }`
            }
          >
            My items
            {window.location.pathname === "/wallet" ? ` (${itemsCount})` : null}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default UnisatWallet;
