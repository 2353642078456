import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import Thumbnail from "./Thumbnail";
import Loader from "./Loader";
import { getHomeBitmaps } from "../utils/functions";
import { AGE, FEE_RATE } from "../utils/constants";

const CategorizedBitmaps = ({ bitmap, setSelectedImage }) => {
  const [loading, setLoading] = useState({
    punkPositive: true,
    punkNegative: true,
    heads: true,
    series: true,
    seriesOrange: true,
  });
  const [data, setData] = useState({
    punkPositive: [],
    punkNegative: [],
    heads: [],
    series: [],
    seriesOrange: [],
  });

  useEffect(() => {
    (async () => {
      /* punkNegative is vbytes as part of the old implementation */

      let d = { ...data };
      let l = { ...loading };

      const punkPosBitmaps = await getHomeBitmaps("punk");
      if (punkPosBitmaps || Object.keys(punkPosBitmaps).length) {
        d = { ...d, punkPositive: punkPosBitmaps };
        l = { ...l, punkPositive: false };
        setData(d);
        setLoading(l);
      }

      const punkNegBitmaps = await getHomeBitmaps("vbytes");
      if (punkNegBitmaps || Object.keys(punkNegBitmaps).length) {
        d = { ...d, punkNegative: punkNegBitmaps };
        l = { ...l, punkNegative: false };
        setData(d);
        setLoading(l);
      }

      const headsBitmaps = await getHomeBitmaps("heads");
      if (headsBitmaps || Object.keys(headsBitmaps).length) {
        d = { ...d, heads: headsBitmaps };
        l = { ...l, heads: false };
        setData(d);
        setLoading(l);
      }

      const seriesBitmaps = await getHomeBitmaps("series");
      if (seriesBitmaps || Object.keys(seriesBitmaps).length) {
        d = { ...d, series: [...seriesBitmaps, ...seriesBitmaps] };
        l = { ...l, series: false };
        setData(d);
        setLoading(l);
      }

      const seriesOrangeBitmaps = await getHomeBitmaps("series_orange");
      if (seriesOrangeBitmaps || Object.keys(seriesOrangeBitmaps).length) {
        d = { ...d, seriesOrange: seriesOrangeBitmaps };
        l = { ...l, seriesOrange: false };
        setData(d);
        setLoading(l);
      }
    })();
  }, []);

  return (
    <div>
      <div className="d-flex align-items-center mt-4 mb-3">
        <p
          className="mb-0 fw-bold me-3"
          style={{
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            color: bitmap === AGE ? "#f7931c" : "#018bff",
          }}
        >
          (+)&nbsp;Punk
        </p>

        <hr
          className={`horizontal-line ${
            bitmap === FEE_RATE && "horizontal-line-blue"
          }`}
          style={{ width: "100%" }}
        />
      </div>

      {loading.punkPositive ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Loader type={AGE} />
        </div>
      ) : (
        <Thumbnail
          shouldNavigate="/punk"
          isHome
          type={bitmap}
          displayedImages={data.punkPositive}
        />
      )}

      <div className="d-flex align-items-center mb-3">
        <p
          className="mb-0 fw-bold me-3"
          style={{
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            color: bitmap === AGE ? "#f7931c" : "#018bff",
          }}
        >
          (-)&nbsp;Punk
        </p>

        <hr
          className={`horizontal-line ${
            bitmap === FEE_RATE && "horizontal-line-blue"
          }`}
          style={{ width: "100%" }}
        />
      </div>

      {loading.punkNegative ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Loader type={FEE_RATE} />
        </div>
      ) : (
        <Thumbnail
          shouldNavigate="/punk"
          isHome
          type={bitmap}
          displayedImages={data.punkNegative}
        />
      )}

      <div className="d-flex align-items-center mb-3">
        <p
          className="mb-0 fw-bold me-3"
          style={{
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            color: bitmap === AGE ? "#f7931c" : "#018bff",
          }}
        >
          Heads
        </p>

        <hr
          className={`horizontal-line ${
            bitmap === FEE_RATE && "horizontal-line-blue"
          }`}
          style={{ width: "100%" }}
        />
      </div>

      {loading.heads ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Loader type={AGE} />
        </div>
      ) : (
        <Thumbnail
          shouldNavigate="/punk"
          isHome
          type={bitmap}
          displayedImages={data.heads}
        />
      )}

      <div className="d-flex align-items-center mb-3">
        <p
          className="mb-0 fw-bold me-3"
          style={{
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            color: bitmap === AGE ? "#f7931c" : "#018bff",
          }}
        >
          Series&nbsp;Color
        </p>

        <hr
          className={`horizontal-line ${
            bitmap === FEE_RATE && "horizontal-line-blue"
          }`}
          style={{ width: "100%" }}
        />
      </div>

      {loading.series ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Loader type={FEE_RATE} />
        </div>
      ) : (
        <Thumbnail
          isHome
          type={bitmap}
          displayedImages={data.series}
          onClick={(image) => setSelectedImage(image)}
        />
      )}

      <div className="d-flex align-items-center mb-3">
        <p
          className="mb-0 fw-bold me-3"
          style={{
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            color: bitmap === AGE ? "#f7931c" : "#018bff",
          }}
        >
          Series&nbsp;Orange
        </p>

        <hr
          className={`horizontal-line ${
            bitmap === FEE_RATE && "horizontal-line-blue"
          }`}
          style={{ width: "100%" }}
        />
      </div>

      {loading.seriesOrange ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Loader type={AGE} />
        </div>
      ) : (
        <Thumbnail
          isHome
          noLike
          type={bitmap}
          displayedImages={data.seriesOrange}
          onClick={(image) => setSelectedImage(image)}
        />
      )}

      <div className="my-4">&nbsp;</div>

      <Tooltip id="not-eligible" place="top" />
    </div>
  );
};

export default CategorizedBitmaps;
