import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  AGE,
  LS_ITEMS_IN_ROW,
  LS_UNISAT_WALLET,
  LS_UNISAT_WALLET_DATA,
  LS_WALLET_ITEMS,
  ROW_INCR,
} from "../utils/constants";
import MainHeader from "../components/MainHeader";
import Thumbnail from "../components/Thumbnail";
import ImageModal from "../components/ImageModal";
import LoadingInfo from "../components/LoadingInfo";
import { getActiveInscriptions } from "../services/wallet";
import { getWalletImages } from "../utils/functions";
import { Navigate } from "react-router";
import ColorsFilter from "../components/Filters/ColorsFilter";

const Wallet = () => {
  if (!localStorage.getItem(LS_UNISAT_WALLET)) window.location = "/";

  const ls_itemsInRow = localStorage.getItem(LS_ITEMS_IN_ROW);
  const ls_unisatWallet = localStorage.getItem(LS_UNISAT_WALLET);
  const ls_walletItems = localStorage.getItem(LS_WALLET_ITEMS);

  const itemsInWallet = ls_walletItems ? JSON.parse(ls_walletItems) : [];
  const unisatWallet = JSON.parse(ls_unisatWallet);

  const [imageData, setImageData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [displayedImages, setDisplayedImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [map, setMap] = useState(false);
  const [noMore, setNoMore] = useState(false);
  const [pointer, setPointer] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [bitmap, setBitmap] = useState(AGE);
  const [allImages, setAllImages] = useState([]);
  const [itemsInRow, setItemsInRow] = useState(ls_itemsInRow || 7);
  const [imagesPerScroll, setImagesPerScroll] = useState(itemsInRow * ROW_INCR);
  const [hasWallet, setHasWallet] = useState(!!unisatWallet.length);
  const [wallets, setWallets] = useState(unisatWallet || []);
  const [walletFilter, setWalletFilter] = useState(true);
  const [walletItems, setWalletItems] = useState(itemsInWallet);
  const [walletLoading, setWalletLoading] = useState(false);
  const [punk, setPunk] = useState(false);

  const resetState = () => {
    setAllImages([]);
    setImageData([]);
    setDisplayedImages([]);
    setPointer(0);
    setPageNum(0);
    setLoading(true);
    setNoMore(false);
    setMap(false);
  };

  useEffect(() => {
    const itemsRow = localStorage.getItem(LS_ITEMS_IN_ROW);
    if (itemsRow && itemsRow != itemsInRow) setItemsInRow(itemsRow);
  }, []);

  useEffect(() => {
    setDisplayedImages(imageData);
  }, [imageData, imagesPerScroll]);

  useEffect(() => {
    const walletData = localStorage.getItem(LS_UNISAT_WALLET_DATA);

    if (walletData) {
      const data = JSON.parse(walletData);
      const bitmaps = _.shuffle(_.filter(data.bitmaps, { type: bitmap }));

      setImageData(bitmaps);
      setLoading(false);
    } else {
      (async () => {
        const walletBitmaps = await getActiveInscriptions();
        const newData = await getWalletImages(walletBitmaps || [], bitmap);

        if (newData === undefined || newData === 0) return setHasWallet(false);

        if (newData.status === "good") {
          setImageData(newData.data);
          setLoading(false);
        }
      })();
    }
  }, [map, punk, bitmap, imagesPerScroll, itemsInRow]);

  if (!unisatWallet || !unisatWallet.length) return <Navigate to="/" />;

  return (
    <div className="gallery-container">
      <MainHeader
        isSecondary
        bitmap={bitmap}
        hasWallet={hasWallet}
        wallets={wallets}
        walletLoading={walletLoading}
        walletItems={walletItems}
        itemsInWallet={itemsInWallet}
        setHasWallet={setHasWallet}
        setWallets={setWallets}
        setWalletLoading={setWalletLoading}
        setWalletItems={setWalletItems}
        setWalletFilter={setWalletFilter}
        resetState={resetState}
      />

      <h1 className="mt-2 mb-4">Wallet Items</h1>

      <div className="mb-5">
        <ColorsFilter
          bitmap={bitmap}
          loading={loading}
          setBitmap={setBitmap}
          resetState={resetState}
        />
      </div>

      <Thumbnail
        type={bitmap}
        map={map}
        imageData={imageData}
        displayedImages={displayedImages}
        onClick={(image) => setSelectedImage(image)}
      />

      {selectedImage && (
        <ImageModal
          image={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}

      <div className="my-4">&nbsp;</div>

      <LoadingInfo
        loading={loading}
        imageData={imageData}
        noMore={noMore}
        bitmap={bitmap}
        itemsInRow={itemsInRow}
        walletFilter={walletFilter}
        setLoading={setLoading}
        setImagesPerScroll={setImagesPerScroll}
        resetState={resetState}
      />
    </div>
  );
};

export default Wallet;
