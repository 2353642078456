import { toast } from "react-toastify";
import _ from "lodash";
import http from "../services/http";
import {
  ROW_INCR,
  LS_ITEMS_IN_ROW,
  BM_ENDPOINT,
  ALL_CATEGORIES,
} from "../utils/constants";

const itemsInRow = localStorage.getItem(LS_ITEMS_IN_ROW) || 7;

const sliceImages = (map, data, pointer) => {
  return map
    ? _.slice(data, pointer, pointer + 1000)
    : _.slice(data, pointer, pointer + 7 * ROW_INCR);
};

export const fetchImageData = async (
  pageNum,
  type,
  map,
  setPageNum,
  setImageData,
  setLoading,
  setNoMore
) => {
  try {
    if (map) {
      const { status, data } = await http.get(BM_ENDPOINT, {
        params: {
          type,
          isMap: map,
        },
      });

      if (status !== 200) return toast.error("Unable to fetch images.");

      setImageData(_.uniqBy(_.shuffle(data), "block"));
      setLoading(false);

      return;
    }

    const { status, data } = await http.get(BM_ENDPOINT, {
      params: {
        type,
        pageNum,
      },
    });

    if (status !== 200) return toast.error("Unable to fetch images.");

    let images = _.shuffle(data);

    setPageNum((prev) => prev + 1);

    if (!images.length) setNoMore(true);

    setImageData((prev) => _.uniqBy([...prev, ...images], "block"));
    setLoading(false);
  } catch (err) {}
};

export const getTotalMeta = async () => {
  try {
    const { status, data } = await http.get(`${BM_ENDPOINT}/meta`);
    return status === 200 ? data : toast.error("Unable to get meta data.");
  } catch (err) {
    return 0;
  }
};

export const getCategoriesMeta = async (category) => {
  try {
    const { status, data } = await http.get(`${BM_ENDPOINT}/category/meta`, {
      params: { category },
    });

    return status === 200 ? data : toast.error("Unable to get meta data.");
  } catch (err) {
    return 0;
  }
};

export const searchImages = async (term, type) => {
  try {
    const { status, data } = await http.get(`${BM_ENDPOINT}/search`, {
      params: { type, term },
    });

    return status === 200 ? data : toast.error("Unable to search.");
  } catch (err) {
    return 0;
  }
};

export const getWalletImages = async (bitmaps, type) => {
  try {
    const { status, data } = await http.post(`${BM_ENDPOINT}/wallet`, {
      inscriptionIds: bitmaps.map(({ inscriptionId }) => inscriptionId),
      type,
    });

    if (status !== 200) {
      toast.error("Unable to get wallet bitmaps.");
      return { data: [], status: "bad" };
    }

    return { data, status: "good" };
  } catch (err) {
    return { data: [], status: "error" };
  }
};

export const getCategoryBitmaps = async (
  type,
  category,
  allImages,
  imageData,
  map,
  pointer,
  setAllImages,
  setImageData,
  setPointer,
  setLoading,
  setNoMore,
  vbytes,
  filters
) => {
  try {
    let images;

    if (allImages.length && !vbytes && !Object.keys(filters).length) {
      images = sliceImages(map, allImages, pointer);
    } else if (category) {
      let res;

      if (vbytes) {
        res = await http.get(`${BM_ENDPOINT}/punk`, {
          params: { isNeg: vbytes, punkType: category, ...filters },
        });
      } else {
        res = await http.get(`${BM_ENDPOINT}/category`, {
          params: { type, category, ...filters },
        });
      }

      if (res.status !== 200)
        return toast.error(`Unable to get bitmaps of ${category}.`);

      images = _.shuffle(res.data);
      setAllImages(images);

      if (images.length === 30) setNoMore(true);

      images = sliceImages(map, images, pointer);
    }

    if (!images.length || allImages.length === imageData.length + images.length)
      setNoMore(true);

    setPointer((prev) => prev + (map ? 1000 : 6 * ROW_INCR));
    setImageData((prev) => _.uniqBy([...prev, ...images], "block"));
    setLoading(false);
  } catch (err) {
    return 0;
  }
};

export const getHomeBitmaps = async (category) => {
  try {
    // const { status, data } = await http.get(`${BM_ENDPOINT}/home`);
    const { status, data } = await http.get(`${BM_ENDPOINT}/top/${category}`);

    if (status !== 200) {
      toast.error("Unable to get wallet bitmaps.");
      return 0;
    }

    return data || [];
  } catch (err) {
    return [];
  }
};

export const calculateItemsInRow = () => {
  const thumbnailsContainer = document.querySelector(".thumbnails");

  if (thumbnailsContainer) {
    const thumbnailItem = thumbnailsContainer.querySelector(".thumbnail");

    if (thumbnailItem) {
      const thumbnailWidth = thumbnailItem.offsetWidth;
      const containerWidth = thumbnailsContainer.offsetWidth;
      const itemsInRow = Math.floor(containerWidth / thumbnailWidth);

      localStorage.setItem(LS_ITEMS_IN_ROW, itemsInRow);
    }
  }
};

export const checkCategory = (category) => ALL_CATEGORIES.includes(category);
