import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import CategoriesFilter from "../components/Filters/CategoriesFilter";
import {
  LS_IS_WALLET_FILTER,
  LS_UNISAT_WALLET,
  LS_WALLET_ITEMS,
} from "../utils/constants";
import Loader from "../components/Loader";
import { useEffect } from "react";
import { searchImages } from "../utils/functions";
import BitmapDetails from "../components/BitmapDetails";

const Bitmap = () => {
  const { type, block } = useParams();

  const ls_isWalletFilter = localStorage.getItem(LS_IS_WALLET_FILTER);
  const ls_walletItems = localStorage.getItem(LS_WALLET_ITEMS);

  const itemsInWallet = ls_walletItems ? JSON.parse(ls_walletItems) : [];
  const walletFilterValue = ls_isWalletFilter
    ? JSON.parse(ls_isWalletFilter)
    : false;

  const [bitmapInfo, setBitmapInfo] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasWallet, setHasWallet] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [walletFilter, setWalletFilter] = useState(walletFilterValue);
  const [walletItems, setWalletItems] = useState(itemsInWallet);
  const [walletLoading, setWalletLoading] = useState(false);

  const resetState = (params = {}) => {
    const { isWallet } = params;
    if (!isWallet) setWalletFilter(walletFilterValue);
  };

  useEffect(() => {
    const accounts = JSON.parse(localStorage.getItem(LS_UNISAT_WALLET));
    if (accounts && accounts.length) setWallets(accounts);
  }, []);

  useEffect(() => {
    (async () => {
      const bitmap = await searchImages(block, type);

      if (!bitmap || !bitmap.length) return;

      setLoading(false);
      setBitmapInfo(bitmap[0]);
    })();
  }, [type, block]);

  if (block.length > 6) return <Navigate to="/" />;

  return (
    <div className="gallery-container">
      <MainHeader
        hasSearch
        customSearchClick={() => <Navigate to="/" />}
        bitmap={type}
        loading={loading}
        walletFilter={walletFilter}
        searchQuery={searchQuery}
        hasWallet={hasWallet}
        wallets={wallets}
        walletLoading={walletLoading}
        walletItems={walletItems}
        itemsInWallet={itemsInWallet}
        setSearchQuery={setSearchQuery}
        setHasWallet={setHasWallet}
        setWallets={setWallets}
        setWalletLoading={setWalletLoading}
        setWalletItems={setWalletItems}
        setWalletFilter={setWalletFilter}
        resetState={resetState}
      />

      <CategoriesFilter loading={loading} bitmap={type} />

      {loading ? (
        <div className="w-100 py-5 my-5 d-flex align-items-center justify-content-center">
          <Loader type={type} />
        </div>
      ) : (
        <div className="m-auto" style={{ width: "fit-content" }}>
          <BitmapDetails wallets={wallets} bitmap={bitmapInfo} />
        </div>
      )}
    </div>
  );
};

export default Bitmap;
