import { toast } from "react-toastify";
import { REPORT_ENDPOINT } from "../utils/constants";
import http from "./http";

export const storeReport = async (block, type, address) => {
  try {
    const { data, status } = await http.post(`${REPORT_ENDPOINT}/create`, {
      block,
      type,
      address,
    });

    if (status !== 200) {
      toast.error("An error occurred while reporting.");
      return 0;
    }

    toast.success("Reported.");

    return data;
  } catch (err) {
    return 0;
  }
};
