import React from "react";
import { AGE, ROW_INCR } from "../utils/constants";
import LoaderAge from "../assets/loading_age.gif";
import LoaderFeerate from "../assets/loading_feerate.gif";
import Loader from "./Loader";

const LoadingInfo = ({
  searchQuery,
  hasFilters,
  loading,
  imageData,
  noMore,
  walletFilter,
  bitmap,
  itemsInRow,
  setLoading,
  setImagesPerScroll,
  resetState,
}) => {
  return (
    <>
      {!loading && !imageData.length && (
        <p className="text-center">No images found.</p>
      )}

      {!loading && (noMore || walletFilter) && (
        <p className="text-center">
          You've reached the end.
          {!walletFilter && !searchQuery && hasFilters && (
            <span
              style={{
                color: "#f7931c",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={resetState}
            >
              {" "}
              Clear filters
            </span>
          )}
        </p>
      )}

      <p className="text-center py-5">
        {loading ? (
          <Loader type={bitmap} />
        ) : (
          !noMore &&
          !walletFilter &&
          !searchQuery &&
          setImagesPerScroll && (
            <button
              className="authBtn mb-5"
              style={{
                width: "auto",
              }}
              onClick={() => {
                setLoading(true);
                setImagesPerScroll((prev) => prev + itemsInRow * ROW_INCR);
              }}
            >
              Load more
            </button>
          )
        )}
      </p>
    </>
  );
};

export default LoadingInfo;
