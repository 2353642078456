import React from "react";
import { Link } from "react-router-dom";
import {
  CATE_ALL,
  CATE_MONDRIAN,
  CATE_PUNK,
  CATE_SERIES,
  CATE_SUB_RANGE,
  FEE_RATE,
} from "../../utils/constants";

const CategoriesFilter = ({ loading, bitmap, punk, category }) => {
  return (
    <div
      className="d-flex align-items-center category-item"
      style={{ marginTop: "1rem", marginBottom: "1rem" }}
    >
      <p
        className="mb-0"
        style={{
          fontWeight: "bold",
          width: 108,
        }}
      >
        Collections
      </p>

      <div className="d-flex align-items-end w-100 filter-btns">
        <div className="land-btn-container me-3">
          <Link to="/">
            <button
              disabled={loading}
              className={
                bitmap === FEE_RATE
                  ? `land-btn-blue punkmap-btn category-btn ${
                      window.location.pathname === "/" && "land-btn-blue-active"
                    }`
                  : `land-btn punkmap-btn category-btn ${
                      window.location.pathname === "/" && "land-btn-active"
                    }`
              }
            >
              <span>TOP</span>
            </button>
          </Link>
        </div>

        <div className="land-btn-container me-3">
          <Link to={`/${CATE_ALL}`}>
            <button
              disabled={loading}
              className={
                bitmap === FEE_RATE
                  ? `land-btn-blue punkmap-btn category-btn ${
                      window.location.pathname.startsWith("/all") &&
                      "land-btn-blue-active"
                    }`
                  : `land-btn punkmap-btn category-btn ${
                      window.location.pathname.startsWith("/all") &&
                      "land-btn-active"
                    }`
              }
            >
              <span>ALL</span>
            </button>
          </Link>
        </div>

        <div className="land-btn-container me-3">
          <Link to={`/${CATE_PUNK}`}>
            <button
              disabled={loading}
              className={
                bitmap === FEE_RATE
                  ? `land-btn-blue punkmap-btn category-btn ${
                      category === CATE_PUNK && "land-btn-blue-active"
                    }`
                  : `land-btn punkmap-btn category-btn ${
                      category === CATE_PUNK && "land-btn-active"
                    }`
              }
            >
              <img
                src={`/images/punk-${
                  category === CATE_PUNK
                    ? "dark"
                    : bitmap === FEE_RATE
                    ? "blue"
                    : "orange"
                }.png`}
                alt="Punk"
              />{" "}
              <span>Punks</span>
            </button>
          </Link>
        </div>

        <div className="land-btn-container me-3">
          <Link to={`/${CATE_SERIES}`}>
            <button
              disabled={loading}
              className={
                bitmap === FEE_RATE
                  ? `land-btn-blue punkmap-btn category-btn ${
                      category === CATE_SERIES && "land-btn-blue-active"
                    }`
                  : `land-btn punkmap-btn category-btn ${
                      category === CATE_SERIES && "land-btn-active"
                    }`
              }
            >
              Series
            </button>
          </Link>
        </div>

        <div
          className="land-btn-container me-3"
          data-tooltip-id="not-available-tooltip"
        >
          <Link to={`/${CATE_SUB_RANGE}`}>
            <button
              disabled
              className={
                bitmap === FEE_RATE
                  ? `land-btn-blue punkmap-btn category-btn ${
                      category === CATE_SUB_RANGE && "land-btn-blue-active"
                    }`
                  : `land-btn punkmap-btn category-btn ${
                      category === CATE_SUB_RANGE && "land-btn-active"
                    }`
              }
            >
              Subrange
            </button>
          </Link>
        </div>

        <div
          className="land-btn-container me-3"
          data-tooltip-id="not-available-tooltip"
        >
          <Link to={`/${CATE_MONDRIAN}`}>
            <button
              disabled
              className={
                bitmap === FEE_RATE
                  ? `land-btn-blue punkmap-btn category-btn ${
                      category === CATE_MONDRIAN && "land-btn-blue-active"
                    }`
                  : `land-btn punkmap-btn category-btn ${
                      category === CATE_MONDRIAN && "land-btn-active"
                    }`
              }
            >
              Mondrians
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CategoriesFilter;
