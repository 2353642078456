import React from "react";
import { Tooltip } from "react-tooltip";
import {
  CATE_HEADS,
  CATE_MONDRIAN,
  CATE_PERFECT_PUNK,
  CATE_PUNK,
  CATE_SERIES,
  CATE_SUB_RANGE,
  CATE_VBYTES,
  FEE_RATE,
  LS_ITEMS_IN_ROW,
} from "../utils/constants";
import { Link } from "react-router-dom";
import ColorsFilter from "./Filters/ColorsFilter";
import { capitalize } from "@mui/material";

const Categories = ({
  isCategory,
  loading,
  category,
  bitmap,
  map,
  punk,
  setPunk,
  setBitmap,
  setMap,
  setCategory,
  resetState,
}) => {
  return (
    <>
      {/* <div className="d-flex align-items-center">
        <p className="mb-0 fw-bold me-3">{capitalize(category)}</p>
        <hr
          className={`horizontal-line ${
            bitmap === FEE_RATE && "horizontal-line-blue"
          }`}
        />
      </div> */}

      {!isCategory ? (
        <div
          className="d-flex align-items-center justify-content-between w-100 colors-map-filter"
          style={{ marginTop: "-0.5rem" }}
        >
          <ColorsFilter
            bitmap={bitmap}
            loading={loading}
            setBitmap={setBitmap}
            resetState={resetState}
          />

          <div className="land-btn-container">
            <button
              disabled={loading}
              onClick={() => {
                localStorage.setItem(LS_ITEMS_IN_ROW, 7);
                setMap(false);
              }}
              className={
                bitmap === FEE_RATE
                  ? `land-btn-blue ${!map && "land-btn-blue-active"}`
                  : `land-btn ${!map && "land-btn-active"}`
              }
            >
              Bitmap detail
            </button>

            <button
              disabled={loading}
              onClick={() => {
                setMap(true);
              }}
              className={
                bitmap === FEE_RATE
                  ? `land-btn-blue ${map && "land-btn-blue-active"}`
                  : `land-btn ${map && "land-btn-active"}`
              }
            >
              Land view
            </button>
          </div>
        </div>
      ) : null}

      {bitmap === FEE_RATE ? (
        <Tooltip
          id="not-available-tooltip"
          place="top"
          content="Coming soon"
          className="custom-tooltip custom-tooltip-blue"
        />
      ) : (
        <Tooltip
          id="not-available-tooltip"
          place="top"
          content="Coming soon"
          className="custom-tooltip"
        />
      )}

      {/* <div
        id="not-available-tooltip"
        role="tooltip"
        class="react-tooltip core-styles-module_tooltip__3vRRp styles-module_tooltip__mnnfp styles-module_dark__xNqje react-tooltip__place-top core-styles-module_show__Nt9eE"
        style="left: 573.325px; top: 198px;"
      >
        Coming soon
        <div
          class="react-tooltip-arrow core-styles-module_arrow__cvMwQ styles-module_arrow__K0L3T"
          style="left: 54.5px; bottom: -4px;"
        ></div>
      </div> */}

      {/* <Tooltip
        id="neg-punk-tooltip"
        className="tooltip"
        place="top"
        content="The utility of the Negative view is predominantly in the realm of art. This perspective allows for an alternative aesthetic interpretation of the blockchain data, broadening the scope of the artistic narratives that can be created and explored. At this stage, the Negative view does not extend beyond the sphere of artistic interpretation and does not contribute to the functionality aspects within the Bitmap theory."
      /> */}
    </>
  );
};

export default Categories;
