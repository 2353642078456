import React from "react";
import "../styles/auth.css";

function Register() {
  return (
    <div className="auth">
      <div className="authBox">
        <h2 className="text-center">Register</h2>

        <div className="authInp my-3">
          <p>Name</p>
          <input type="text" placeholder="Enter your full name" />
        </div>

        <div className="authInp">
          <p>Email</p>
          <input type="email" placeholder="youremail@mail.com" />
        </div>

        <div className="authInp my-3">
          <p>Password</p>
          <input
            type="password"
            autoComplete="new-password"
            placeholder="Minimum 10 characters"
          />
        </div>

        <div className="authInp">
          <p>Confirm Password</p>
          <input
            type="password"
            autoComplete="new-password"
            placeholder="Re-enter password"
          />
        </div>

        <button className="authBtn mt-4">Signup</button>
      </div>
    </div>
  );
}

export default Register;
