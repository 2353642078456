import React from "react";
import { FEE_RATE } from "../utils/constants";

const ImageModal = ({ type, image, onClose }) => {
  return (
    <div className="modal" onClick={() => onClose()}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-btn" onClick={() => onClose()}>
          &times;
        </span>

        {image.url ? (
          <>
            <img width={363} src={image.url} alt="" />
            <h3 style={{ marginTop: -60 }}>{image.block}</h3>
            <div className="details">
              <p>Date: {image.date}</p>
              <p>Cost: ₿ {image.costInBitcoin}</p>
              <p>Bytes: {image.bytes}</p>
              <p>Transactions: {image.transactions}</p>
              <p>Average Fee Rate: {image.averageFeeRate}</p>
            </div>
          </>
        ) : (
          <div className="details">
            <p
              className={`highlight ${type === FEE_RATE && "highlight-blue"}`}
              style={{ fontSize: 24 }}
            >
              {image.block}.bitmap
            </p>
            <p className="mb-1">
              Inscription Number: {image.inscriptionNumber}
            </p>
            <p>Inscription ID: {image.inscriptionId}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageModal;
