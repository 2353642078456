import React from "react";
import { AGE, FEE_RATE, LS_BM_TYPE } from "../../utils/constants";

const ColorsFilter = ({
  bitmap,
  loading,
  setBitmap,
  resetState,
  isCategory,
}) => {
  const changeColor = (type) => {
    if (bitmap !== type) {
      localStorage.setItem(LS_BM_TYPE, type);
      setBitmap(type);
      resetState();
    }
  };

  return (
    <div
      className="d-flex align-items-center category-item"
      style={{ margin: "1rem 0", ...(isCategory && { marginBottom: "3rem" }) }}
    >
      <p
        className="mb-0"
        style={{
          fontWeight: "bold",
          width: isCategory ? 108 : "7.5rem",
        }}
      >
        Colors
      </p>

      <div className="land-btn-container w-100">
        <button
          disabled={loading}
          onClick={() => changeColor(AGE)}
          className={
            bitmap === FEE_RATE
              ? `land-btn-blue ${bitmap === AGE && "land-btn-blue-active"}`
              : `land-btn ${bitmap === AGE && "land-btn-active"}`
          }
        >
          Orange
        </button>

        <button
          disabled={loading}
          onClick={() => changeColor(FEE_RATE)}
          className={`land-btn ${
            bitmap === FEE_RATE && "land-btn-blue-active"
          }`}
        >
          Colors
        </button>
      </div>
    </div>
  );
};

export default ColorsFilter;
