import React from "react";
import { capitalize } from "lodash";
import { FEE_RATE } from "../utils/constants";
import { storeReport } from "../services/report";
import DangerIcon from "../icons/warning.svg";
import DangerBlueIcon from "../icons/warning-blue.svg";

const BitmapDetails = ({ wallets, bitmap }) => {
  const reportBitmap = async () => {
    if (window.confirm("Are you sure?"))
      await storeReport(bitmap.block, bitmap.type, wallets?.[0]);
  };

  return (
    <div className="py-5 my-4 d-flex bitmap-details" style={{ gap: "5rem" }}>
      <div>
        {bitmap.wasabiUrl || bitmap.url ? (
          <img
            width={363}
            src={bitmap.wasabiUrl || bitmap.url}
            alt={bitmap.block}
          />
        ) : (
          <div
            className={`no-bitmap ${
              bitmap.type === FEE_RATE && "no-bitmap-blue"
            }`}
          >
            <h1>{bitmap.block}.bitmap</h1>
          </div>
        )}
      </div>

      <div>
        <h1>{bitmap.block}</h1>

        <p style={{ fontSize: 18, opacity: 0.8 }}>{capitalize(bitmap.type)}</p>

        <div
          className="mb-5 d-flex align-items-center"
          style={{ gap: "0.75rem" }}
        >
          {bitmap.categories.map(
            (category, i) =>
              category.key !== "vbytes" && (
                <p
                  key={i}
                  className={`mb-0 categories-pill ${
                    bitmap.type === FEE_RATE && "categories-pill-blue"
                  }`}
                >
                  {category.name}
                </p>
              )
          )}
        </div>

        {bitmap.costInBitcoin ? (
          <div>
            <div className="d-flex mb-4 mt-1">
              <div style={{ width: 220 }}>
                <p
                  style={{ opacity: 0.8, fontWeight: "lighter" }}
                  className="mb-1"
                >
                  Cost
                </p>

                <h4 style={{ fontWeight: "normal" }}>
                  ₿ {bitmap.costInBitcoin}
                </h4>
              </div>

              <div>
                <p
                  style={{ opacity: 0.8, fontWeight: "lighter" }}
                  className="mb-1"
                >
                  Bytes
                </p>

                <h4 style={{ fontWeight: "normal" }}>{bitmap.bytes}</h4>
              </div>
            </div>

            <div className="d-flex mb-4 mt-1">
              <div style={{ width: 220 }}>
                <p
                  style={{ opacity: 0.8, fontWeight: "lighter" }}
                  className="mb-1"
                >
                  Average Fee Rate
                </p>

                <h4 style={{ fontWeight: "normal" }}>
                  {bitmap.averageFeeRate || "-"}
                </h4>
              </div>

              <div>
                <p
                  style={{ opacity: 0.8, fontWeight: "lighter" }}
                  className="mb-1"
                >
                  Transactions
                </p>

                <h4 style={{ fontWeight: "normal" }}>{bitmap.transactions}</h4>
              </div>
            </div>

            {bitmap.date !== "Invalid Date" ? (
              <div className="mb-4 mt-1">
                <p
                  style={{ opacity: 0.8, fontWeight: "lighter" }}
                  className="mb-1"
                >
                  Date
                </p>

                <h4 style={{ fontWeight: "normal" }}>{bitmap.date}</h4>
              </div>
            ) : null}

            {bitmap.inscriptionNumber ? (
              <div className="mb-5 mt-1">
                <p
                  style={{ opacity: 0.8, fontWeight: "lighter" }}
                  className="mb-1"
                >
                  Inscription number
                </p>

                <h4 style={{ fontWeight: "normal" }}>
                  {bitmap.inscriptionNumber}
                </h4>
              </div>
            ) : null}

            {bitmap.inscriptionId ? (
              <div className="mb-5 mt-1">
                <p
                  style={{ opacity: 0.8, fontWeight: "lighter" }}
                  className="mb-1"
                >
                  Inscription ID
                </p>

                <h4 style={{ fontWeight: "normal", wordBreak: "break-word" }}>
                  {bitmap.inscriptionId}
                </h4>
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            <div className="mb-4 mt-1">
              <p
                style={{ opacity: 0.8, fontWeight: "lighter" }}
                className="mb-1"
              >
                Inscription number
              </p>

              <h4 style={{ fontWeight: "normal" }}>
                {bitmap.inscriptionNumber}
              </h4>
            </div>

            <div className="mb-5 mt-1">
              <p
                style={{ opacity: 0.8, fontWeight: "lighter" }}
                className="mb-1"
              >
                Inscription ID
              </p>

              <h4 style={{ fontWeight: "normal", wordBreak: "break-word" }}>
                {bitmap.inscriptionId}
              </h4>
            </div>
          </div>
        )}

        <p
          className={`highlight d-flex align-items-center ${
            bitmap.type === FEE_RATE && "highlight-blue"
          }`}
          style={{ gap: "0.5rem" }}
          onClick={reportBitmap}
        >
          <img
            width={24}
            src={bitmap.type === FEE_RATE ? DangerBlueIcon : DangerIcon}
            alt="Danger Icon"
          />
          <span>Please click here if this image is not correct or corrupt</span>
        </p>
      </div>
    </div>
  );
};

export default BitmapDetails;
