import React, { useState } from "react";
import { capitalize } from "lodash";
import DatePicker from "react-datepicker";
import { KeyboardArrowDown } from "@mui/icons-material";
import "react-datepicker/dist/react-datepicker.css";

const RangeFilter = ({ type, handleFilter, isDate, isNegative }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState({ start: "", end: "" });

  return (
    <div className="mb-3">
      <div
        className={`filter-header d-flex align-items-center justify-content-between ${
          isOpen ? "pb-3" : "pb-2"
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="mb-0 fw-bold">
          {capitalize(type.split("_").join(" "))}
          &nbsp;
        </p>

        <KeyboardArrowDown
          style={{
            marginTop: 3,
            color: isNegative ? "#018bff" : "#f7931c",
            transform: isOpen ? "rotate(360deg)" : "rotate(270deg)",
            transition: "all 300ms",
          }}
        />
      </div>

      <div
        className="mt-1"
        style={{
          height: isOpen ? 124 : 0,
          opacity: isOpen ? 100 : 0,
          transition: "all 300ms",
          padding: 2,
          position: "relative",
          ...(!isOpen && { overflow: "hidden" }),
        }}
      >
        {isOpen ? null : (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          />
        )}

        <div className="d-flex align-items-center justify-content-between">
          {isDate ? (
            <>
              <div>
                <p className="mb-1" style={{ opacity: 0.6, fontSize: 12 }}>
                  Start date
                </p>

                <DatePicker
                  className={`filter-input filter-input-date ${
                    isNegative && "filter-input-blue"
                  }`}
                  placeholderText="mm/dd/yyyy"
                  selected={value.start}
                  onChange={(date) =>
                    setValue((prev) => ({ ...prev, start: date }))
                  }
                />
              </div>

              <span>—</span>

              <div>
                <p className="mb-1" style={{ opacity: 0.6, fontSize: 12 }}>
                  End date
                </p>

                <DatePicker
                  className={`filter-input filter-input-date ${
                    isNegative && "filter-input-blue"
                  }`}
                  placeholderText="mm/dd/yyyy"
                  selected={value.end}
                  onChange={(date) =>
                    setValue((prev) => ({ ...prev, end: date }))
                  }
                />
              </div>
            </>
          ) : (
            <>
              <input
                placeholder="0"
                className={`filter-input ${isNegative && "filter-input-blue"}`}
                type="text"
                value={value.start}
                onChange={(e) =>
                  setValue((prev) => ({ ...prev, start: e.target.value }))
                }
              />

              <span>—</span>

              <input
                placeholder="100"
                className={`filter-input ${isNegative && "filter-input-blue"}`}
                type="text"
                value={value.end}
                onChange={(e) =>
                  setValue((prev) => ({ ...prev, end: e.target.value }))
                }
              />
            </>
          )}
        </div>

        <button
          onClick={() => handleFilter(type, value)}
          className={`authBtn mt-3 ${isNegative && "authBtn-blue"}`}
          style={{ padding: "8px 12px" }}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default RangeFilter;
