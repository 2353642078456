import React, { useEffect, useState } from "react";
import RangeFilter from "./Filters/RangeFilter";
import { getCategoriesMeta } from "../utils/functions";

const SideFilters = ({ handleFilter, isNegative, category }) => {
  const [meta, setMeta] = useState({});

  useEffect(() => {
    if (category) {
      setMeta({});

      (async () => {
        const data = await getCategoriesMeta(category);

        if (data) {
          let date = new Date(data.created).toDateString().split(" ");
          data.created = `${date[1]} ${date[3]}`;
          setMeta(data);
        }
      })();
    }
  }, [category]);

  return (
    <div style={{ flex: 0.17 }}>
      <RangeFilter
        type="transaction"
        handleFilter={handleFilter}
        isNegative={isNegative}
      />

      <RangeFilter
        type="byte"
        handleFilter={handleFilter}
        isNegative={isNegative}
      />

      <RangeFilter
        type="date"
        handleFilter={handleFilter}
        isDate
        isNegative={isNegative}
      />

      <RangeFilter
        type="fee_rate"
        handleFilter={handleFilter}
        isNegative={isNegative}
      />

      <RangeFilter
        type="cost"
        handleFilter={handleFilter}
        isNegative={isNegative}
      />

      <hr
        className="my-3"
        style={{
          borderColor: isNegative ? "#018bff" : "#f7931c",
          borderWidth: "2px",
          opacity: 1,
        }}
      />

      <div>
        <p className="mb-1 fw-light">
          Items <span className="fw-medium">{meta.count || "..."}</span>
        </p>

        <p className="fw-light">
          Created <span className="fw-medium">{meta.created || "..."}</span>
        </p>
      </div>
    </div>
  );
};

export default SideFilters;
