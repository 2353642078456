export const ROW_INCR = 5;
export const AGE = "age";
export const FEE_RATE = "feerate";
export const AUTH_TOKEN = "token";
export const BASE_WALLET_API_URL = "https://api2.ordinalsbot.com";
export const BASE_API_URL = window.location.host.endsWith("bitmapview.xyz")
  ? "https://bitmap-back-end.onrender.com/api"
  : "https://bitmap-backend-staging.onrender.com/api";
export const BM_ENDPOINT = "/bitmaps";
export const WALLET_ENDPOINT = "/wallets";
export const AUTH_ENDPOINT = "/auth";
export const REPORT_ENDPOINT = "/reports";
export const MAX_BM_LENGTH = 14;
export const BM_EXTENSION = "bitmap";
export const LS_BM_TYPE = "bm_type";
export const LS_ITEMS_IN_ROW = "items_in_row";
export const LS_UNISAT_WALLET = "unisat_wallet";
export const LS_UNISAT_WALLET_DATA = "unisat_wallet_data";
export const LS_LIKES_DISLIKES = "likes_dislikes";
export const LS_IS_WALLET_FILTER = "is_wallet_filter";
export const LS_WALLET_ITEMS = "wallet_items";
export const LS_SEARCH_QUERY = "search_query";
export const CATE_VBYTES = "vbytes";
export const CATE_ALL = "all";
export const CATE_PUNK = "punk";
export const CATE_PERFECT_PUNK = "perfect_punk";
export const CATE_HEADS = "heads";
export const CATE_2TX = "2tx";
export const CATE_SERIES = "series";
export const CATE_SUB_RANGE = "sub_range";
export const CATE_MONDRIAN = "mondrian";
export const CATE_ALL_PUNKS = [
  CATE_PUNK,
  CATE_PERFECT_PUNK,
  CATE_HEADS,
  CATE_VBYTES,
  CATE_2TX,
];
export const ALL_CATEGORIES = [
  CATE_ALL,
  ...CATE_ALL_PUNKS,
  CATE_SERIES,
  CATE_SUB_RANGE,
  CATE_MONDRIAN,
];
