import React, { useEffect, useState } from "react";
import {
  calculateItemsInRow,
  fetchImageData,
  searchImages,
} from "../utils/functions";
import {
  AGE,
  LS_IS_WALLET_FILTER,
  LS_ITEMS_IN_ROW,
  LS_UNISAT_WALLET,
  LS_WALLET_ITEMS,
} from "../utils/constants";
import Categories from "../components/Categories";
import MainHeader from "../components/MainHeader";
import CategoriesFilter from "../components/Filters/CategoriesFilter";
import CategorizedBitmaps from "../components/CategorizedBitmaps";
import ImageModal from "../components/ImageModal";
import Thumbnail from "../components/Thumbnail";
import LoadingInfo from "../components/LoadingInfo";
import { Navigate } from "react-router-dom";

const All = () => {
  const ls_itemsInRow = localStorage.getItem(LS_ITEMS_IN_ROW);
  const ls_unisatWallet = localStorage.getItem(LS_UNISAT_WALLET);
  const ls_isWalletFilter = localStorage.getItem(LS_IS_WALLET_FILTER);
  const ls_walletItems = localStorage.getItem(LS_WALLET_ITEMS);

  const itemsInWallet = ls_walletItems ? JSON.parse(ls_walletItems) : [];
  const walletFilterValue = ls_isWalletFilter
    ? JSON.parse(ls_isWalletFilter)
    : false;

  const [searchQuery, setSearchQuery] = useState("");
  const [imageData, setImageData] = useState([]);
  const [displayedImages, setDisplayedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [map, setMap] = useState(false);
  const [noMore, setNoMore] = useState(false);
  const [pointer, setPointer] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [bitmap, setBitmap] = useState(AGE);
  const [allImages, setAllImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [itemsInRow, setItemsInRow] = useState(ls_itemsInRow || 7);
  const [hasWallet, setHasWallet] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [walletFilter, setWalletFilter] = useState(walletFilterValue);
  const [walletItems, setWalletItems] = useState(itemsInWallet);
  const [walletLoading, setWalletLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [punk, setPunk] = useState(false);

  const resetState = (params = {}) => {
    setAllImages([]);
    setImageData([]);
    setDisplayedImages([]);
    setPointer(0);
    setPageNum(0);
    setNoMore(false);
    setMap(false);

    const { isWallet, notCategory } = params;

    if (!isWallet) setWalletFilter(walletFilterValue);
    if (!notCategory) {
      setCategory("");
      setPunk(false);
    }
  };

  useEffect(() => {
    const itemsRow = localStorage.getItem(LS_ITEMS_IN_ROW);
    if (itemsRow && itemsRow != itemsInRow) setItemsInRow(itemsRow);

    const accounts = JSON.parse(localStorage.getItem(LS_UNISAT_WALLET));
    if (accounts && accounts.length) setWallets(accounts);
  }, []);

  useEffect(() => {
    const searchTimer = setTimeout(async () => {
      setLoading(true);

      if (!searchQuery && !walletFilter && !category) {
        setLoading(false);
        resetState();
      }

      if (searchQuery) {
        const newData = await searchImages(searchQuery, bitmap);

        setImageData(newData || []);
        setLoading(false);
      }
    }, 300);

    return () => clearTimeout(searchTimer);
  }, [searchQuery, bitmap]);

  useEffect(() => {
    if (map) {
      setLoading(true);
      (async () =>
        await fetchImageData(
          pageNum,
          bitmap,
          map,
          setPageNum,
          setImageData,
          setLoading,
          setNoMore
        ))();
    }
  }, [bitmap, map, pageNum]);

  return (
    <div className="gallery-container">
      <MainHeader
        hasSearch
        customSearchClick={() => <Navigate to="/" />}
        bitmap={bitmap}
        loading={loading}
        walletFilter={walletFilter}
        searchQuery={searchQuery}
        hasWallet={hasWallet}
        wallets={wallets}
        walletLoading={walletLoading}
        walletItems={walletItems}
        itemsInWallet={itemsInWallet}
        setSearchQuery={setSearchQuery}
        setHasWallet={setHasWallet}
        setWallets={setWallets}
        setWalletLoading={setWalletLoading}
        setWalletItems={setWalletItems}
        setWalletFilter={setWalletFilter}
        resetState={resetState}
      />

      <CategoriesFilter
        loading={loading}
        bitmap={bitmap}
        punk={punk}
        category={category}
      />

      {map ? (
        <>
          <div className="my-4">
            <Thumbnail
              type={bitmap}
              map={map}
              imageData={imageData}
              displayedImages={displayedImages}
              onClick={(image) => setSelectedImage(image)}
            />
          </div>

          <LoadingInfo
            hasFilters
            searchQuery={searchQuery}
            loading={loading}
            imageData={imageData}
            noMore={noMore}
            walletFilter={walletFilter}
            bitmap={bitmap}
            itemsInRow={itemsInRow}
            setLoading={setLoading}
            resetState={resetState}
          />
        </>
      ) : (
        <CategorizedBitmaps
          bitmap={bitmap}
          setSelectedImage={setSelectedImage}
        />
      )}

      {selectedImage && (
        <ImageModal
          type={bitmap}
          image={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </div>
  );
};

export default All;
