import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import All from "./pages/All";
import Wallet from "./pages/Wallet";
import Bitmap from "./pages/Bitmap";
import Index from "./pages/Index";
import Category from "./pages/Category";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Maintenance from "./pages/Maintenance";
import { storeOrUpdateWallet } from "./services/wallet";
import { LS_LIKES_DISLIKES, LS_UNISAT_WALLET } from "./utils/constants";
import "react-toastify/dist/ReactToastify.min.css";
import "swiper/css";
import "./styles/App.css";

function App() {
  useEffect(() => {
    (async () => {
      const accounts = localStorage.getItem(LS_UNISAT_WALLET);

      if (accounts && accounts.length) {
        const data = await storeOrUpdateWallet(JSON.parse(accounts)[0]);

        if (data && Object.keys(data).length) {
          localStorage.setItem(
            LS_LIKES_DISLIKES,
            JSON.stringify({
              likedBitmaps: data.likedBitmaps,
              dislikedBitmaps: data.dislikedBitmaps,
            })
          );
        }
      }
    })();
  }, []);

  useEffect(() => {
    const handleContextmenu = (e) => e.preventDefault();
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  const isProd = false; // window.location.host.endsWith("bitmapview.xyz")

  return (
    <div className="app">
      {isProd ? (
        <Maintenance />
      ) : (
        <>
          {/* <div
            className="text-center p-2"
            style={{ backgroundColor: "#8c4c00" }}
          >
            Important Notice Beta version: Site content and functionality may be
            incomplete or subject to change during this testing phase.
          </div> */}

          <div className="container">
            <BrowserRouter>
              <Routes>
                <Route path="/all" exact element={<Index />} />
                <Route path="/wallet" exact element={<Wallet />} />
                <Route path="/bitmap/:type/:block" exact element={<Bitmap />} />
                <Route path="/register" exact element={<Register />} />
                <Route path="/login" exact element={<Login />} />
                <Route path="/:type" element={<Category />} />
                <Route path="/" exact element={<All />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </BrowserRouter>

            <ToastContainer position="top-right" theme="dark" />
          </div>

          <footer style={{ zIndex: 99999 }}>
            <p className="m-0">
              Copyright © 2023. All rights reserved. Powered by{" "}
              <span className="highlight">Bitcoin</span>.
            </p>
          </footer>
        </>
      )}
    </div>
  );
}

export default App;
