import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";
import { toast } from "react-toastify";
import { dislikeBitmap, likeBitmap } from "../services/wallet.js";
import { calculateItemsInRow } from "../utils/functions.js";
import {
  CATE_ALL_PUNKS,
  FEE_RATE,
  LS_LIKES_DISLIKES,
  LS_UNISAT_WALLET,
  LS_UNISAT_WALLET_DATA,
  LS_WALLET_ITEMS,
} from "../utils/constants";
import ThumbsUp from "./Icons/ThumbsUp";
import ThumbsDown from "./Icons/ThumbsDown";
import Heart from "./Icons/Heart";

const Thumbnail = ({
  category,
  isAll,
  isWithFilter,
  isPunkNegative,
  type,
  map,
  imageData,
  displayedImages,
  isHome,
  noLike,
  shouldNavigate,
}) => {
  const [isEligible, setIsEligible] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [address, setAddress] = useState("");
  const [likesDislikes, setLikesDislikes] = useState(null);
  const [slidesCount, setSlidesCount] = useState(7);
  const [loading, setLoading] = useState(false);

  useState(async () => {
    if (window.innerWidth < 768) {
      await setSlidesCount(1);
      setLoading(true);
    }

    const walletData = localStorage.getItem(LS_UNISAT_WALLET_DATA);
    setWallet(walletData ? JSON.parse(walletData) : null);

    const addr = localStorage.getItem(LS_UNISAT_WALLET);
    setAddress(addr ? JSON.parse(addr)?.[0] : null);

    const ld = localStorage.getItem(LS_LIKES_DISLIKES);
    setLikesDislikes(ld ? JSON.parse(ld) : null);
  }, []);

  useEffect(() => {
    const walletItems = localStorage.getItem(LS_WALLET_ITEMS);
    if (walletItems) setIsEligible(JSON.parse(walletItems).length >= 5);
  }, [wallet]);

  useEffect(() => {
    calculateItemsInRow();

    window.addEventListener("resize", calculateItemsInRow);
    window.addEventListener("load", calculateItemsInRow);

    return () => {
      window.removeEventListener("resize", calculateItemsInRow);
      window.removeEventListener("load", calculateItemsInRow);
    };
  }, []);

  return (
    <div
      className="thumbnails"
      style={{
        gap: !map && (isWithFilter ? 10 : 20),
        flex: isWithFilter ? 0.83 : 1,
      }}
    >
      {isHome && !loading ? (
        <Swiper
          loop={true}
          freeMode
          modules={[Autoplay, FreeMode]}
          spaceBetween={10}
          slidesPerView={slidesCount}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        >
          {displayedImages.map((image, i) => (
            <SwiperSlide key={i}>
              <ThumbnailItem
                likesDislikes={likesDislikes}
                address={address}
                isEligible={isEligible}
                wallet={wallet}
                noLike={noLike}
                isPunkNegative={isPunkNegative}
                type={type}
                image={image}
                shouldNavigate={shouldNavigate}
                map={map}
                category={category}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : map ? (
        imageData.map((image, i) => (
          <ThumbnailItem
            likesDislikes={likesDislikes}
            address={address}
            isEligible={isEligible}
            wallet={wallet}
            noLike={noLike}
            isPunkNegative={isPunkNegative}
            key={i}
            type={type}
            image={image}
            shouldNavigate={shouldNavigate}
            map={map}
            category={category}
          />
        ))
      ) : (
        displayedImages.map((image, i) => (
          <ThumbnailItem
            isAll={isAll}
            likesDislikes={likesDislikes}
            address={address}
            isEligible={isEligible}
            wallet={wallet}
            noLike={noLike}
            isPunkNegative={isPunkNegative}
            key={i}
            type={type}
            image={image}
            shouldNavigate={shouldNavigate}
            map={map}
            category={category}
          />
        ))
      )}
    </div>
  );
};

const ThumbnailItem = ({
  category,
  image,
  shouldNavigate,
  noLike,
  map,
  type,
  wallet,
  address,
  likesDislikes,
  isEligible,
}) => {
  const navigate = useNavigate();

  const [imageError, setImageError] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const [votes, setVotes] = useState(0);
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    setVotes((image.likedBy?.length || 0) - (image.dislikedBy?.length || 0));

    const categories = image.categories.map((category) => category.key);
    setShouldShow(
      window.location.pathname.startsWith("/punk") ||
        (categories.some((c) => CATE_ALL_PUNKS.includes(c)) &&
          window.screen.width > 480)
    );

    // if (category) {
    //   setShouldShow(CATE_ALL_PUNKS.includes(category));
    // } else {
    //   const categories = image.categories.map((category) => category.key);

    //   setShouldShow(
    //     window.location.pathname.startsWith("/punk") ||
    //       (categories.some((c) => CATE_ALL_PUNKS.includes(c)) &&
    //         window.screen.width > 480)
    //   );
    // }
  }, [image, category]);

  useState(() => {
    if (likesDislikes) {
      let { likedBitmaps, dislikedBitmaps } = likesDislikes;

      likedBitmaps = likedBitmaps.map((bitmap) => bitmap._id);
      dislikedBitmaps = dislikedBitmaps.map((bitmap) => bitmap._id);

      setIsLiked(
        likedBitmaps && likedBitmaps.length
          ? likedBitmaps.includes(image._id)
          : false
      );

      setIsDisliked(
        dislikedBitmaps && dislikedBitmaps.length
          ? dislikedBitmaps.includes(image._id)
          : false
      );
    }
  }, []);

  const like = async () => {
    if (!wallet) return alert("Please connect your wallet first.");
    if (!isEligible)
      return alert("You must have at least 5 bitmaps in your wallet.");

    const prev = isLiked;
    const prevVotes = votes;

    if (isDisliked) {
      setVotes(votes + 2);
    } else if (prev) {
      setVotes(votes - 1);
    } else if (!prev) {
      setVotes(votes + 1);
    }

    setIsLiked(!prev);
    setIsDisliked(false);

    const data = await likeBitmap(address, image._id);
    if (!data) {
      setIsLiked(prev);
      setIsDisliked(isDisliked);
      setVotes(prevVotes);
    }
  };

  const dislike = async () => {
    if (!wallet) return alert("Please connect your wallet first.");
    if (!isEligible)
      return alert("You must have at least 5 bitmaps in your wallet.");

    const prev = isDisliked;
    const prevVotes = votes;

    if (isLiked) {
      setVotes(votes - 2);
    } else if (prev) {
      setVotes(votes + 1);
    } else if (!prev) {
      setVotes(votes - 1);
    }

    setIsLiked(false);
    setIsDisliked(!prev);

    const data = await dislikeBitmap(address, image._id);
    if (!data) {
      setIsLiked(isLiked);
      setIsDisliked(prev);
      setVotes(prevVotes);
    }
  };

  const url = image.url || image.wasabiUrl;
  const detailsUrl = shouldNavigate || `/bitmap/${image.type}/${image.block}`;

  return (
    <div
      className={`thumbnail ${
        (!url || imageError) &&
        (type === FEE_RATE ? "no-thumbnail-blue" : "no-thumbnail")
      }`}
      style={{
        marginBottom: map && -7,
        marginTop: type === FEE_RATE && 10,
        ...((!url || imageError) && { cursor: "pointer" }),
      }}
      onClick={() => (!url || imageError ? navigate(detailsUrl) : null)}
    >
      {/* onClick={() => (!url ? onClick(image) : null)} */}
      {/* onClick={() => onClick(image)} */}
      {url && !imageError ? (
        <>
          {map ? (
            <img
              onError={() => setImageError(true)}
              onClick={() => navigate(detailsUrl)}
              src={url}
              alt={image.block}
              style={{ width: map && 25 }}
            />
          ) : (
            <div style={{ minHeight: 212.39 }}>
              <img
                onError={() => setImageError(true)}
                onClick={() => navigate(detailsUrl)}
                src={url}
                alt={image.block}
                style={{ width: map && 25 }}
              />
            </div>
          )}

          <div
            className={`block-number ${
              !noLike && shouldShow && "block-number-punk"
              // (shouldShow || window.location.pathname.startsWith("/punk")) &&
              // "block-number-punk"
            }`}
            style={{
              display: map && "none",
              marginTop: type === FEE_RATE && -20,
              ...(!shouldShow &&
                !window.location.pathname.startsWith("/punk") && {
                  height: 44,
                }),
            }}
          >
            <p className="mb-0">
              {/* {shouldShow || window.location.pathname.startsWith("/punk") ? ( */}
              {!noLike && shouldShow ? (
                <span
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="You must have at least 5 bitmaps in your wallet"
                  style={{
                    marginTop: 4,
                    ...(!wallet
                      ? { opacity: 0.5, cursor: "unset !important" }
                      : {}),
                  }}
                  onClick={dislike}
                >
                  <ThumbsDown
                    className={`thumbs-icon-svg thumbs-down ${
                      isDisliked && "thumbs-active"
                    }`}
                    fill="#fff"
                    style={{ width: "20px" }}
                  />
                </span>
              ) : null}

              <span>{image.block}</span>

              {/* {shouldShow || window.location.pathname.startsWith("/punk") ? ( */}
              {
                !noLike && shouldShow ? (
                  <span
                    className="thumbs-up-container"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="You must have at least 5 bitmaps in your wallet"
                    onClick={like}
                    style={
                      !wallet
                        ? { opacity: 0.5, cursor: "unset !important" }
                        : {}
                    }
                  >
                    <ThumbsUp
                      className={`thumbs-icon-svg thumbs-up ${
                        isLiked && "thumbs-active"
                      }`}
                      fill="#fff"
                      style={{ width: "20px", marginTop: -8 }}
                    />

                    <span
                      style={{
                        opacity: 0.75,
                        fontSize: 10,
                        position: "absolute",
                        left: "50%",
                        bottom: "-68%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {votes}
                    </span>
                  </span>
                ) : null
                // <span
                //   className="thumbs-up-container"
                //   data-tooltip-id="my-tooltip"
                //   data-tooltip-content="You must have at least 5 bitmaps in your wallet"
                //   style={{
                //     marginLeft: 6,
                //     marginTop: -2,
                //     ...(!wallet
                //       ? { opacity: 0.5, cursor: "unset !important" }
                //       : {}),
                //   }}
                //   onClick={like}
                // >
                //   <Heart
                //     className={`thumbs-icon-svg thumbs-down ${
                //       isLiked && "thumbs-active"
                //     }`}
                //     fill="#fff"
                //     style={{ width: "20px" }}
                //   />

                //   <span
                //     style={{
                //       opacity: 0.75,
                //       fontSize: 10,
                //       position: "absolute",
                //       left: "50%",
                //       bottom: "-80%",
                //       transform: "translate(-50%, -50%)",
                //     }}
                //   >
                //     {votes}
                //   </span>
                // </span>
              }
            </p>
          </div>
        </>
      ) : (
        <span>{image.block}.bitmap</span>
      )}
    </div>
  );
};

export default Thumbnail;
