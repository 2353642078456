import React, { useState } from "react";
import { toast } from "react-toastify";
import { getCurrentUser, login } from "../services/auth";
import "../styles/auth.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  if (getCurrentUser()) return (window.location.href = "/");

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await login(email, password);

      setLoading(false);
      window.location.href = "/";
    } catch (err) {
      setLoading(false);

      if (err.response && err.response.status === 400) {
        console.log(err);
        toast.error("An error occurred.");
      }
    }
  };

  return (
    <div className="auth">
      <form className="authBox" onSubmit={submit}>
        <h2 className="text-center">Sign In</h2>

        <div className="authInp my-3">
          <label htmlFor="email">Email</label>

          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="authInp">
          <label htmlFor="password">Password</label>

          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="authBtn mt-4" disabled={loading}>
          Login
        </button>
      </form>
    </div>
  );
}

export default Login;
