import axios from "axios";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../utils/constants";

const requestMap = new Map();

axios.defaults.baseURL = BASE_API_URL;

// prod: BASE_API_URL
// dev: process.env.REACT_APP_API_URL

axios.interceptors.response.use(null, (err) => {
  const expectedError =
    err.response && err.response.status >= 400 && err.response.status < 500;

  if (
    !expectedError &&
    err.name !== "CanceledError" &&
    err.code !== "ERR_CANCELED"
  ) {
    console.error(err);
    toast.error("An unexpected error occurred!");
  }

  return Promise.reject(err);
});

axios.interceptors.request.use(
  (config) => {
    const requestKey = JSON.stringify(config);

    if (requestMap.has(requestKey)) {
      const cancelToken = requestMap.get(requestKey);
      cancelToken.cancel("Previous request cancelled due to a new request.");
    }

    const cancelToken = axios.CancelToken.source();
    requestMap.set(requestKey, cancelToken);

    config.cancelToken = cancelToken.token;
    return config;
  },
  (error) => Promise.reject(error)
);

const setJwt = (jwt) => {
  axios.defaults.headers.common["x-auth-token"] = jwt;
};

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  isCancel: axios.isCancel,
  setJwt,
};

export default http;
